import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Layout from "../components/Layout";

import { usePageTracking } from "../utils/usePageTracking";

function AboutUs({ data }) {
  const { content } = data.allContentfulStaticPage.nodes[0];

  usePageTracking();

  return (
    <Layout location="O nama">{documentToReactComponents(content.json)}</Layout>
  );
}

export const pageQuery = graphql`
  query AboutUsQuery {
    allContentfulStaticPage(filter: { title: { eq: "O nama" } }) {
      nodes {
        content {
          json
        }
      }
    }
  }
`;

export default AboutUs;
